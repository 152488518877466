import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import MessagesContainer from "../../components/Messages/Chat/messagesContainer";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import AutomationItem from "../../xdeprecated/Automation/automationItem";
import AutomationList from "../../xdeprecated/Automation/automationList";
import BaseChart from "../../components/Board/Container/baseChart";
import SearchUsers from "../../components/Utilities/Search/searchUsers";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ProjectHeader from "../../components/Headers/projectHeader";
import clsx from "clsx";
import { ChromePicker } from "react-color";
import {
  addChannel,
  followProject,
  updateProjectColor,
} from "../../api/firestore";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import { BiLock } from "react-icons/bi";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Rooms from "../../components/Messages/Rooms/rooms";
import IconButton from "@material-ui/core/IconButton";
import { BiCircle } from "react-icons/bi";
import ProjectProfile from "../../components/Profile/Project/projectProfile";
import Avatar from "@material-ui/core/Avatar";
import ChatBase from "../../components/Messages/Container/ChatBase";
import Snackbar from '@material-ui/core/Snackbar';
import { GiConsoleController } from "react-icons/gi";


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function BaseView(props) {
  const classes = useStyles();
  const [switchState, setSwitch] = React.useState(false);
  const [isChatOpen, setIsChatOpen] = React.useState(false);
  const [graphMDandLG, setGraphMDandLG] = React.useState(12);
  const [width, setWidth] = React.useState("100vw");
  const [baseWidth, setBaseWidth] = React.useState("100vw");
  const [chatWidth, setChatWidth] = React.useState("0vw");
  const [users, setUsers] = React.useState([]);
  const [stretch, setStretch] = React.useState(7);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [color, setColor] = React.useState(props.channel.color);
  const [savedColor, setSavedColor] = React.useState(props.channel.color);
  const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
  const [openSettings, setSettingsOpen] = React.useState(false);
  const openColorPicker = () => {
    setColorPickerOpen(true);
  };
  const handleClickOpenSettings = () => {
    setSettingsOpen(!colorPickerOpen);
  };
  const handleCloseSettings = () => {
    setSettingsOpen(false);
  };
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  const changeColor = () => {
    setColor(savedColor);
    updateProjectColor(savedColor, props.channel.channelID);
    setColorPickerOpen(false);
  };

  const saveColor = (color) => {
    setSavedColor(color.hex);
  };
  const openChat = () => {
    if (windowDimensions.width < 1335) {
      setOpen(true)
    } else {
      setIsChatOpen(!isChatOpen);
      if (baseWidth === "100vw") {
        setBaseWidth("70vw");
        setChatWidth("30vw");
      } else {
        setBaseWidth("100vw");
        setChatWidth("0vw");
      }
    }
  };

  const getUsers = () => {};

  const handleFollow = () => {
    let pIds = props.user.projectIDs;
    pIds[props.channel.channelID] = { viewPort: [0, 0], zoom: 0.5 };
    followProject(props.user.email, props.channel.channelID, pIds);
  };

  const isFollowing = () => {
    let val = props.user.projects.includes(props.channel.channelID);
    return val;
  };

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
      if (getWindowDimensions().width  < 1335  ) { 
            if (isChatOpen) { 
                setIsChatOpen(false)
                if (baseWidth === "100vw") {
                    setBaseWidth("70vw");
                    setChatWidth("30vw");
                } else {
                    setBaseWidth("100vw");
                    setChatWidth("0vw");
                }
    }
    }
}

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getUsers();
    // openChat()
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        severity="warning"
        message="Expand Screen to Chat!"
      />
      {/*<CssBaseline />*/}
      <div className={classes.root}>
        <Grid className={classes.rootView} container spacing={0}>
          {/*<Grid className={classes.root} xs={3} md={3} lg={3} container>*/}

          {/*</Grid>*/}
          <Box
            flexDirection={"row"}
            display="flex"
            className={classes.root}
            xs={12}
            md={12}
            lg={12}
            container
          >
            {isChatOpen ? (
              <ChatBase
                isChatOpen = {isChatOpen}
                chatWidth={chatWidth}
                channel={props.channel}
                messages={props.messages}
                automations={props.automations}
                channel={props.channel}

                user={props.user}
              />
            ) : null}
            <BaseChart
              isChatOpen={props.isChatOpen}
              authenticated={props.authenticated}
              baseWidth={baseWidth}
              handleClickOpenSettings={handleClickOpenSettings}
              channel={props.channel}
              messages={props.messages}
              user={props.user}
              isChatOpen={isChatOpen}
              viewWidth={width}
              openChat={openChat}
            />
          </Box>
        </Grid>
      </div>
      <Dialog
        open={openSettings}
        fullWidth={true}
        // maxWidth={maxWidth}
        onClose={handleCloseSettings}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box display="flex" flexDirection="column">
            <p style={{ fontSize: 15, fontWeight: 400, marginTop: 10 }}>
              {" "}
              PROFILE{" "}
            </p>
            <ProjectProfile channel={props.channel} user={props.user} />
            <Divider />
            <p style={{ fontSize: 15, fontWeight: 400, marginTop: 10 }}>
              {" "}
              THEME
            </p>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <p style={{ fontWeight: 600 }}> Color </p>

              <IconButton>
                <BiCircle
                  onClick={openColorPicker}
                  size={25}
                  style={{
                    color: "white",
                    backgroundColor: savedColor,
                    borderRadius: 100,
                  }}
                />
              </IconButton>

              {colorPickerOpen ? (
                <ChromePicker
                  onChangeComplete={(color) => saveColor(color)}
                  color={savedColor}
                />
              ) : null}
            </Box>
            <Button
              onClick={changeColor}
              variant={"contained"}
              style={{
                backgroundColor: savedColor,
                marginTop: 10,
                marginBottom: 10,
                width: 180,
                borderRadius: 10,
              }}
            >
              {" "}
              <p style={{ color: "white", margin: 0 }}> Save Color </p>
            </Button>
          </Box>
          <Divider />
        </DialogContent>
      </Dialog>
    </div>
  );
}

const drawerWidth = 72;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor:'#363638',
    display: "flex",
  },
  rootView: {
    height: "100vh",
    flexGrow: 1,
    overflow: "hidden",
    display: "flex",
    backgroundColor:'#363638',

  },
  box: {
    flexGrow: 1,
    padding: 0,
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    margin: 0,
  },

  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },

  privateBoard: {
    height: "90vh",
  },

  container: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#F8F8F8",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  toolbar: {
    paddingRight: 25,
    backgroundColor: "white",
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

{
  /*<Switch*/
}
{
  /*    checked={switchState}*/
}
{
  /*    onChange={handleSwitch}*/
}
{
  /*    color="primary"*/
}
{
  /*    name="checkedB"*/
}

{
  /*    inputProps={{'aria-label': 'primary checkbox'}}*/
}
{
  /*/>*/
}
{
  /*{(!switchState)*/
}
{
  /*    ?*/
}
{
  /*    <p style={{marginTop: 12, color: '#353C49'}}> Manager View </p>*/
}
{
  /*    :*/
}
{
  /*    <p style={{marginTop:12, color: '#3162F0'}}> Team View </p>*/
}
{
  /*}*/
}

//
//
// <Box display = 'flex' justify= 'center' alignItems = 'center' flexDirection = 'row' >
//     <p style ={{fontSize: 17, fontWeight: 800}}> AutoPilot</p>
//     <Switch/>
// </Box>

{
  /*<ChannelHeader channel = {props.channel} user = {props.user}/>*/
}

{
  /*<Grid container justify = 'center' alignItems = 'center' className = {classes.privateBoard}>*/
}
{
  /*    <Box flexDirection = 'column' display = 'flex' justifyContent={'center'} alignItems = 'center'>*/
}
{
  /*        <BiLock style = {{}} size = {70} />*/
}
{
  /*        <p> {props.channel.name} is private.</p>*/
}

{
  /*        <Button variant={'contained'} style = {{backgroundColor: props.channel.color}}> <p style = {{color:'white', margin:0}}> Follow </p> </Button>*/
}
{
  /*    </Box>*/
}
{
  /*</Grid>*/
}

{
  /*<Grid style = {{boxShadow: `0px 3px 10px rgba(0, 0, 0, 0.15)`, zIndex: 5, border:1}} xs={4} sm={4} md={4} lg={4} direction='column' container>*/
}
{
  /*    <Rooms channel={props.channel} messages={props.messages}*/
}
{
  /*                       automations={props.automations} user={props.user}/>*/
}
{
  /*</Grid>*/
}

//FOLLOWVIEW

//
// <div className={classes.root}>
//     <AppBar
//         style={{boxShadow: "0px 0px 0px #C8CEEB", marginTop:0,}}
//         position="absolute"
//         color = '#F7F7F7'
//         className={clsx(classes.appBar, open && classes.appBarShift)}
//     >
//         <Toolbar style = {{boxShadow: `5px 1px 10px -5px #838383`}} noWrap className={classes.toolbar}>
//         </Toolbar>
//         <Divider/>
//     </AppBar>
//
//     <Grid container justify = 'center' alignItems = 'center' className = {classes.privateBoard}>
//         <Box flexDirection = 'column' display = 'flex' justifyContent={'center'} alignItems = 'center'>
//             <Box  style = {{margin: 5, padding: 3, marginTop: -40}} border = {2} borderColor = {'#D0D1D3'} borderRadius = {50}>
//
//                 <Avatar className = {classes.large} src = {props.channel.img}/>
//             </Box>
//             <p style = {{fontSize: 26, margin:5, fontWeight: 600}}> {props.channel.name}</p>
//             <p style = {{fontSize: 16, margin: 5, }}> {props.channel.bio} </p>
//
//             <Button onClick={handleFollow} variant={'contained'} style = {{backgroundColor: props.channel.color, margin: 10}}> <p style = {{color:'white', margin:0}}> Follow </p> </Button>
//         </Box>
//     </Grid>
// </div>

{
  /*<AppBar*/
}
{
  /*    style={{boxShadow: `0px 3px 10px rgba(0, 0, 0, 0.15)`, marginTop:0,}}*/
}
{
  /*    position="absolute"*/
}
{
  /*    color = '#F7F7F7'*/
}
{
  /*    className={clsx(classes.appBar, open && classes.appBarShift)}*/
}
{
  /*>*/
}
{
  /*    <Toolbar style = {{boxShadow: `5px 1px 10px -5px #838383`}} noWrap className={classes.toolbar}>*/
}
{
  /*        <ProjectHeader handleClickOpenSettings = { handleClickOpenSettings} user = {props.user} channel = {props.channel} />*/
}
{
  /*    </Toolbar>*/
}
{
  /*    <Divider/>*/
}
{
  /*</AppBar>*/
}

export default BaseView;
