import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover/Popover";
import {BsCardText,BsImage, BsStickies, BsCardHeading, BsCardList, BsFileSpreadsheet, BsFileText, BsKanban, BsWindow} from "react-icons/bs"
import {BiLineChart,BiUpVote, BiCode,BiCard,BiChat, BiHash, BiBrain, BiFile, BiUser, BiData, BiFolder,BiSpreadsheet,BiNote, BiCubeAlt, BiTable, BiDetail, BiPencil, BiRuler, BiMessageAlt, BiLink, BiCheckboxChecked, BiEdit, BiMenu, BiText, BiUserCircle, BiUndo, BiSticker, BiBox, BiSquare, BiCalendar, BiUpvote} from "react-icons/bi";
import Divider from "@material-ui/core/Divider";
import {IoMdHand} from "react-icons/all";



function FlowController(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const onDragStart = (event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };

    return (
        <Grid style = {{height: 200, pointerEvents: !props.authenticated ? 'none' : 'default', marginTop:-200}} container justify ='center' alignItems = 'center' >
            <Box borderRadius = {100} display = 'flex' flexDirection ='column' style = {{boxShadow: `0px 3px 10px rgba(0, 0, 0, 0.20)`, padding: 10, overflow:'hidden', color: props.user.theme === 'dark' ? 'white' : '#363638', backgroundColor:props.user.theme === 'light' ? 'white' : '#363638'}}>
                <Box  display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 10, marginTop: 15, marginBottom: 20}} >
                    <IoMdHand onClick={()=> props.setIsAdding(false)}  style = {{color: props.isAdding ? props.user.theme === 'dark' ? 'white': 'black' :  '#6E7AFF'}} size = {28}/>
                </Box>
                {/* <Box onClick={()=>props.setIsAdding('note')} display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 10,marginBottom: 15, }} onDragStart={(event) => onDragStart(event, 'note')} draggable>
                    <BiNote style = {{color: props.isAdding !== 'note' ? props.user.theme === 'dark' ? 'white': 'black' :  '#6E7AFF', }} size = {23}/>
                </Box>
                <Box onClick={()=>props.setIsAdding('note')} display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 10,marginBottom: 15, }} onDragStart={(event) => onDragStart(event, 'card')} draggable>
                    <BiCard style = {{color: props.isAdding !== 'note' ? props.user.theme === 'dark' ? 'white': 'black' :  '#6E7AFF', }} size = {23}/>
                </Box>
                <Box onClick={()=>props.setIsAdding('thought')} display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 10,marginBottom: 15, }} onDragStart={(event) => onDragStart(event, 'thought')} draggable>
                    <BiBrain style = {{color: props.isAdding !== 'thought' ? props.user.theme === 'dark' ? 'white': 'black' :  '#6E7AFF', }} size = {23}/>
                </Box> */}
                <Box display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 10,marginBottom: 15, }} onDragStart={(event) => onDragStart(event, 'manualavatar')} draggable>
                    <BiUserCircle style = {{color: props.isAdding !== 'manualavatar' ? props.user.theme === 'dark' ? 'white': 'black' :  '#6E7AFF', }} size = {23}/>
                </Box>
                {/* <Box onClick={()=>props.setIsAdding('label')} display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 10,marginBottom: 15, }} onDragStart={(event) => onDragStart(event, 'label')} draggable>
                    <BiText style = {{color: props.isAdding !== 'label'? props.user.theme === 'dark' ? 'white': 'black' :  '#6E7AFF', }} size = {23}/>
                </Box> */}
                {/* <Box onClick={()=>props.setIsAdding('todo')} display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 10,marginBottom: 15, }} onDragStart={(event) => onDragStart(event, 'calendar')} draggable>
                    <BiCalendar style = {{color: props.isAdding !== 'todo' ? props.user.theme === 'dark' ? 'white': 'black' :  '#6E7AFF', }} size = {28}/>
                </Box> */}
                {/* <Box onClick={()=>props.setIsAdding('todo')} display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 10,marginBottom: 15, }} onDragStart={(event) => onDragStart(event, 'todo')} draggable>
                    <BiCheckboxChecked style = {{color: props.isAdding !== 'todo' ? props.user.theme === 'dark' ? 'white': 'black' :  '#6E7AFF', }} size = {28}/>
                </Box>
                <Box onClick={()=>props.setIsAdding('chat')} display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 10,marginBottom: 15, }} onDragStart={(event) => onDragStart(event, 'chat')} draggable>
                    <BiChat style = {{color: props.isAdding !== 'chat' ? props.user.theme === 'dark' ? 'white': 'black' :  '#6E7AFF', }} size = {28}/>
                </Box> */}
                {/* <Box onClick={()=>props.setIsAdding('vote')} display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 10,marginBottom: 15, }} onDragStart={(event) => onDragStart(event, 'vote')} draggable>
                    <BiUpvote style = {{color: props.isAdding !== 'vote' ? props.user.theme === 'dark' ? 'white': 'black' :  '#6E7AFF', }} size = {28}/>
                </Box>
                <Box onClick={()=>props.setIsAdding('marketmetric')} display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 10,marginBottom: 15, }} onDragStart={(event) => onDragStart(event, 'marketmetric')} draggable>
                    <BiData style = {{color: props.isAdding !== 'marketmetric' ? props.user.theme === 'dark' ? 'white': 'black' :  '#6E7AFF', }} size = {28}/>
                </Box> */}
                {/* <Box onClick={()=>props.setIsAdding('button')} display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 10,marginBottom: 15, }} onDragStart={(event) => onDragStart(event, 'button')} draggable>
                    <BiLink style = {{color: props.isAdding !== 'button' ? props.user.theme === 'dark' ? 'white': 'black' :  '#6E7AFF', }} size = {28}/>
                </Box>
                <Box onClick={()=>props.setIsAdding('image')} display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 10,marginBottom: 15, }} onDragStart={(event) => onDragStart(event, 'image')} draggable>
                    <BiLink style = {{color: props.isAdding !== 'image' ? props.user.theme === 'dark' ? 'white': 'black' :  '#6E7AFF', }} size = {28}/>
                </Box> */}
                {/* <Box onClick={()=>props.setIsAdding('metric')} display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 10,marginBottom: 15, }} onDragStart={(event) => onDragStart(event, 'metric')} draggable>
                    <BiHash style = {{color: props.isAdding !== 'metric' ? props.user.theme === 'dark' ? 'white': 'black' :  '#6E7AFF', }} size = {24}/>
                </Box> */}
               
                {/* <Box onClick={()=>props.setIsAdding('button')} display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 10,marginBottom: 15, }} onDragStart={(event) => onDragStart(event, 'button')} draggable>
                    <BiLink style = {{color: props.isAdding !== 'button' ? props.user.theme === 'dark' ? 'white': 'black' :  '#6E7AFF', }} size = {24}/>
                </Box>
                <Box onClick={()=>props.setIsAdding('image')} display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 10,marginBottom: 15, }} onDragStart={(event) => onDragStart(event, 'image')} draggable>
                    <BsImage style = {{color: props.isAdding !== 'image' ? props.user.theme === 'dark' ? 'white': 'black' :  '#6E7AFF', }} size = {22}/>
                </Box>
                <Box onClick={()=>props.setIsAdding('image')} display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 10,marginBottom: 15, }} onDragStart={(event) => onDragStart(event, 'graph')} draggable>
                    <BsFileSpreadsheet style = {{color: props.isAdding !== 'image' ? props.user.theme === 'dark' ? 'white': 'black' :  '#6E7AFF', }} size = {22}/>
                </Box>
                <Box onClick={()=>props.setIsAdding('image')} display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 10,marginBottom: 15, }} onDragStart={(event) => onDragStart(event, 'signup')} draggable>
                    <BiUser style = {{color: props.isAdding !== 'image' ? props.user.theme === 'dark' ? 'white': 'black' :  '#6E7AFF', }} size = {22}/>
                </Box>

                <Box onClick={()=>props.setIsAdding('image')} display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 10,marginBottom: 15, }} onDragStart={(event) => onDragStart(event, 'landmetric')} draggable>
                    <BiFile style = {{color: props.isAdding !== 'image' ? props.user.theme === 'dark' ? 'white': 'black' :  '#6E7AFF', }} size = {22}/>
                </Box>

                <Box onClick={()=>props.setIsAdding('image')} display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 10,marginBottom: 15, }} onDragStart={(event) => onDragStart(event, 'marketmetric')} draggable>
                    <BiFile style = {{color: props.isAdding !== 'image' ? props.user.theme === 'dark' ? 'white': 'black' :  '#6E7AFF', }} size = {22}/>
                </Box> */}
               
            </Box>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

export default FlowController;

{/*<Popover*/}
{/*    id={id}*/}
{/*    style ={{marginTop: 80, marginRight: 15}}*/}
{/*    open={open}*/}
{/*    anchorEl={anchorEl}*/}
{/*    onClose={handleClose}*/}
{/*    anchorOrigin={{*/}
{/*        vertical: 'left',*/}
{/*        horizontal: 'left',*/}
{/*    }}*/}
{/*    transformOrigin={{*/}
{/*        vertical: 'left',*/}
{/*        horizontal: 'left',*/}
{/*    }}*/}
{/*>*/}

//
// <ButtonGroup
//     variant='text' color="primary" aria-label="contained primary button group">
//     <IconButton  onClick={handleClick} color="secondary" aria-label="">
//         <AddIcon style = {{color:props.color, height: 24, width: 24}} />
//     </IconButton>
//     {/*<Button>Two</Button>*/}
//     {/*<Button>Three</Button>*/}
// </ButtonGroup>

{/*<Box display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 8,}} onDragStart={(event) => onDragStart(event, 'boxfront')} draggable   >*/}
{/*    <BiRectangle style = {{backgroundColor:'black', borderRadius: 5}} size = {22}/>*/}
{/*</Box>*/}

{/*<Box display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 8,}} onDragStart={(event) => onDragStart(event, 'metric')} draggable   >*/}
{/*    <BiRuler size = {25}/>*/}
{/*</Box>*/}

{/*<Box display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 8,}} onDragStart={(event) => onDragStart(event, 'divider')} draggable   >*/}
{/*    <BiMinus   size = {25}/>*/}
{/*</Box>*/}
{/*<Box display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 8,}} onDragStart={(event) => onDragStart(event, 'button')} draggable   >*/}
{/*    <BiLink size = {25}/>*/}
{/*</Box>*/}
{/*<Box display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 8,}} onDragStart={(event) => onDragStart(event, 'avatar')} draggable   >*/}
{/*<BiUserCircle   size = {25}/>*/}
{/*</Box>*/}
{/*<Box display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 8,}} onDragStart={(event) => onDragStart(event, 'todo')} draggable   >*/}
{/*    <BiCheckboxChecked size = {27}/>*/}
{/*</Box>*/}
{/*<Box display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 8,}} onDragStart={(event) => onDragStart(event, 'graph')} draggable   >*/}
{/*    <BiLineChart   size = {25}/>*/}
{/*</Box>*/}



{/*<Box display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 10,}} onDragStart={(event) => onDragStart(event, 'box')} draggable   >*/}
{/*    <BsWindow size = {25}/>*/}
{/*</Box>*/}
{/*<Divider/>*/}



{/*<Box  display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 8,}} onDragStart={(event) => onDragStart(event, 'label')} draggable   >*/}
{/*    <BiText size = {25}/>*/}
{/*</Box>*/}
{/*<Divider/>*/}
{/*<Box display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 10,}} onDragStart={(event) => onDragStart(event, 'document')} draggable   >*/}
{/*    <BsFileText  size = {25}/>*/}
{/*</Box>*/}
{/*<Box display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 8,}} onDragStart={(event) => onDragStart(event, 'folder')} draggable   >*/}
{/*    <BiFolder size = {23}/>*/}
{/*</Box>*/}
{/*<Box display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 10,}} onDragStart={(event) => onDragStart(event, 'kanban')} draggable   >*/}
{/*    <BsCardList size = {25}/>*/}
{/*</Box>*/}
{/*<Box display = 'flex' alignItems = 'center' justifyContent = 'center' style = {{margin: 8,}} onDragStart={(event) => onDragStart(event, 'table')} draggable   >*/}
{/*    <BiData  size = {23}/>*/}
{/*</Box>*/}