import React, { memo, useEffect } from "react";
import TodoNode from "../Todo/todoNode";
import Box from "@material-ui/core/Box";
import { Handle } from "react-flow-renderer";
import TextField from "@material-ui/core/TextField/TextField";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import NodeEditor from "../../../../Editor/NodeEditor/nodeEditor";
import { Divider, makeStyles, Button } from "@material-ui/core";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Rnd } from "react-rnd";
import {
  BiEdit,
  BiRctangle,
  BiMove,
  BiChevronUp,
  BiChevronDown,
  BiText,
  BiChevronLeft,
  BiCheckboxChecked,
  BiListUl,
  BiUserCircle,
  BiMessageAltDetail,
} from "react-icons/bi";
import IconButton from "@material-ui/core/IconButton";
import TitleAndOptions from "../../../NodeUtils/NodeHeaders/titleAndOptions";
import { PowerOffSharp } from "@material-ui/icons";
import { db, storage } from "../../../../../api/firebase";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

let tinycolor = require("tinycolor2");

const mData = [{timeStamp: 1639678918.128, floor: 0.2}]

const data = [
  {
    name: "",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

let global_bg_c = "white";

export default function LandMetricsNode(props) {
  const classes = useStyles();
  const [done, setDone] = React.useState(props.data.done);
  const [text, setText] = React.useState(props.data.text);
  const [isListings,setStateIsListings] = React.useState(props.data.isListing)
  const [textColor, setTextColor] = React.useState(props.data.textColor);
  const [backgroundColor, setBackGroundColor] = React.useState(
    props.data.backgroundColor
  );
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  const [size, setSize] = React.useState(props.data.size);
  const [img, setImg] = React.useState(props.data.img);
  const [editing, setEditing] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [marketData, setMarketData] = React.useState(props.data.marketData);
  const [update, setUpdate] = React.useState(Date.now())
  const [projectData, setProjectData] = React.useState(null)
  const [isHour, setIsHour] = React.useState(true)
  const [currentFloor, setCurrentFloor] = React.useState(0)
  const [currentListed, setCurrentListed] = React.useState(0)
  const [isMetric, setIsMetric] = React.useState(props.data.is)


  const setIsListings = (bool) => {
    setStateIsListings(bool)
    props.data.isListing = bool;

  }

  const handleFireBaseUpload = async (e, imageAsFile) => {
    e.preventDefault();
    console.log("start of upload");
    if (imageAsFile === "") {
      console.error(`not an image, the image file is a ${typeof imageAsFile}`);
      return;
    }
    if (imageAsFile) {
      const uploadTask = storage
        .ref(`/images/${imageAsFile.name}`)
        .put(imageAsFile);
      //initiates the firebase side uploading
      uploadTask.on(
        "state_changed",
        (snapShot) => {
          //takes a snap shot of the process as it is happening
          console.log(snapShot);
        },
        (err) => {
          //catches the errors
          console.log(err);
        },
        async () => {
          // gets the functions from storage refences the image storage in firebase by the children
          // gets the download url then sets the image from firebase as the value for the imgUrl key:
          storage
            .ref("images")
            .child(imageAsFile.name)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              setImg((prevObject) => ({ ...prevObject, imgUrl: fireBaseUrl }));
            });
        }
      );
    }
  };

  const handleImageAsFile = async (e) => {
    setEditing(true);
    const image = e.target.files[0];
    // await setImageAsFile(image);
    await handleFireBaseUpload(e, image);
    // setIsLoadingImage(false);
  };
  props.data.img = img;

  const handleSave = () => {
    console.log(img);
  };

  const getColor = () => {
    //TODO fix hardcode
    let color = tinycolor("black");
    if (color.isDark()) {
      return "white";
    } else {
      return "black";
    }
  };

  const handleSetEditorState = (editorState) => {
    const contentState = editorState.getCurrentContent();
    let save = JSON.stringify(convertToRaw(contentState));
    setEditorState(editorState);
    props.data.textContent = save;
    // data.save();
  };

  async function getMeData() {
  try {
    let response = await fetch(
      "https://api-mainnet.magiceden.io/rpc/getCollectionEscrowStats/solana_money_boys"
    );
    let responseJson = await response.json();
    // console.log('MEDATA',responseJson)
    setCurrentFloor(responseJson.results.floorPrice)
    setCurrentListed(responseJson.results.listedCount)


    return responseJson;
  } catch (error) {
    console.error(error);
  }
}

  const formatMarketData = (rawData) => {

    if (isListings && rawData) {
      for (let i = 0; i<rawData.length; i++){

        rawData[i].uniqueHolder = rawData[i].uniqueHolder;
        rawData[i].time = rawData[i].date.toDate().getHours()
  
  
      }
       
    } else {
      rawData.hour.shift()
      for (let i = 0; i<rawData.hour.length; i++){
        rawData.hour[i].price = rawData.hour[i].price / 1000000000
        rawData.hour[i].time = rawData.hour[i].date.toDate().getHours()
      }

    }



    return rawData
  }

  useEffect(async () => {
    getMeData()
    console.log('call')
    await db.collection("projects").doc('SolanaMoneyBoys')
    .onSnapshot(function(doc) {
        let projectData = doc.data();
        console.log('pdata', projectData)
        if (projectData) {
        
          let formattedData = isListings ? formatMarketData(projectData.uniqueHolder) : formatMarketData(projectData.floorPrice)
          setProjectData(formattedData)
        }
    })
  }, []);

  return (
    <>
      <Box
        className = 'nodrag'

        borderRadius={10}
        style={{
          margin: 5,
          fontFamily: "Plus Jakarta Sans",
          border: 1,
          borderStyle: "solid",
          borderWidth: 3,
          borderColor: "white",
          overflow: "hidden",
          width: isListings ? 500: 1000,
          backgroundColor: "#242527",
        }}
        // border = {1}
        // borderColor = {data.color}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
      {/* <Button onClick = {()=>setIsMetric(!isMetric)}> Switch </Button> */}
      {projectData ? 

        <> 
        {isListings ? 

          <p
          style={{ color: "white", fontWeight: 500, fontSize: 30, margin: 20 }}
        >
                  Listed Count: {currentListed}

        </p>

        : 
        <p
          style={{ color: "white", fontWeight: 500, fontSize: 30, margin: 20 }}
        >
                  Floor Price: {currentFloor/1000000000} SOL 

        </p>
        }
        <p
          style={{
            color: "white",
            fontWeight: 400,
            fontSize: 18,
            margin: 20,
            marginTop: -5,
            color: "white",
          }}
        >
          {" "}
          Graph updates every hour{" "}
        </p>

        <Divider style={{ color: "white" }} />
        {/* <ResponsiveContainer width="100%" height="100%"> */}
          <AreaChart
            width={isListings ? 500: 1000}
            height={isListings ? 300 : 400}
            update = {update}
            data={isListings ? projectData : projectData.hour }
            margin={{
              top: 10,
              right: 30,
              left: -10,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeColor = 'grey' strokeDasharray="3 3" />
            <XAxis dataKey="time" />
            <YAxis />
            {/* <Tooltip /> */}
            <Area
              type="monotone"
              dataKey={isListings ? 'uniqueHolder' : "price"}
              stroke="#3D7EFF"
              fill="#3D7EFF"
            />
          </AreaChart>
        {/* </ResponsiveContainer> */}
        <Box
          className={props.data.className}
          display = 'flex'
          justifyContent = 'space-around'
          style={{
            overflow: "hidden",
            margin: 23,
            marginTop: -5,
            fontSize: 25,
            fontWeight: 600,
            color: "white",
            fontFamily: "Plus Jakarta Sans",
          }}
        >
          {/* <p> Total Listed: {marketData.listedCount} </p> */}
        </Box>
        </>
        : <p> Loading... </p> 
      }
    
        {/*<Box borderLeft = {1} borderColor = {data.color} display ='flex' flexDirection = 'column ' style = {{flex:1}}>*/}
        {/*    <BiMove style = {{margin: 5, color: data.color}} size = {15} />*/}

        {/*    <IconButton onClick = {fold} style ={{margin:0, padding: 0}} >*/}
        {/*    {  isFolded*/}
        {/*        ?<BiChevronUp style = {{margin: 5, color: data.color}} size = {17} />*/}
        {/*        : <BiChevronDown  style = {{margin: 5, color: data.color}} size = {17} />*/}

        {/*    }*/}
        {/*    </IconButton>*/}

        {/*</Box>*/}

        {/* <Handle
          type="source"
          id="a"
          position="top"
          style={{
            zIndex: 12,
            backgroundColor: props.data.color,
            boxShadow: `0px 3px 10px rgba(0, 0, 0, 0.15)`,
          }}
          // onConnect={(params) => console.log('handle onConnect', params)}
        />
        <Handle
          type="source"
          id="k"
          position="left"
          style={{
            zIndex: 12,
            backgroundColor: props.data.color,
            boxShadow: `0px 3px 10px rgba(0, 0, 0, 0.15)`,
          }}
          // onConnect={(params) => console.log('handle onConnect', params)}
        />

        <Handle
          type="source"
          id="j"
          position="right"
          style={{
            zIndex: 12,
            backgroundColor: props.data.color,
            boxShadow: `0px 3px 10px rgba(0, 0, 0, 0.15)`,
          }}
          // onConnect={(params) => console.log('handle onConnect', params)}
        />

        <Handle
          type="source"
          id="c"
          position="bottom"
          style={{
            zIndex: 12,
            backgroundColor: props.data.color,
            boxShadow: `0px 3px 10px rgba(0, 0, 0, 0.15)`,
          }}
          // onConnect={(params) => console.log('handle onConnect', params)}
        /> */}
      </Box>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  cont1: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },

  cont2: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    paddingRight: 20,
  },
  box: {
    padding: 0,
    display: "start",
    overflow: "auto",
    flexDirection: "column",
  },

  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },

  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  fixedHeight: {
    height: 350,
  },
  popover: {},

  toolbar: {
    fontSize: 12,
    backgroundColor: global_bg_c,
  },
}));

// {img ?
//     <img style = {{width:500, height:200,border:1, borderStyle:'solid', borderWidth: 3,borderColor:'white', objectFit:'cover', overflow:'hidden',}} src= {img.imgUrl}></img>

//     :
//         <>
//         <input className={classes.input} id="contained-button-file" accept="image/*" type ='file' onChange={handleImageAsFile} />
//         <label htmlFor="contained-button-file">
//         {/* <Button variant="contained"> Upload </Button> */}
//         </label>

//     </>

// }


// async function getMeData() {
//   try {
//     let response = await fetch(
//       "https://api-mainnet.magiceden.io/rpc/getCollectionEscrowStats/solana_money_boys"
//     );
//     let responseJson = await response.json();
//     return responseJson;
//   } catch (error) {
//     console.error(error);
//   }
// }

// useEffect(async () => {

    
//     console.log(Date.now()/1000 - marketData.at(-1).timeStamp)
//     console.log(marketData)
//   if (marketData && (Date.now()/1000 - marketData.at(-1).timeStamp) > 10) { 
//         let data = await getMeData();
//         console.log(data)
//         let mData = marketData
//         let results = data.results;
//         let point = {
//             timeStamp: Date.now()/1000,
//             floor: results.floorPrice/1000000000,

//         }
//         mData.push(point)
//         if (mData.length > 10){
//             mData.shift()
//         }

//       //   mData = [mData.slice(4)]
//         setMarketData(mData);
//         setUpdate(Date.now())
//         console.log(marketData)
//         props.data.marketData = marketData


//   }


// }, []);