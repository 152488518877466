import React from 'react';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField/TextField";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import {makeStyles} from "@material-ui/core/styles";
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Divider from "@material-ui/core/Divider";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import Button from "@material-ui/core/Button";

function AutomationRecurrenceEditor(props) {
    const classes = useStyles();
    return (
        <Box style = {{padding: 20, marginTop: -10, minWidth: 200}}>
            <form noValidate autoComplete="off">
                <Grid xs = {12} md ={12} lg={12} direction = 'column' container>
                    <Grid direction='row' justify = 'flex-start' alignItems='center' container xs = {12} md ={12} lg={12}>
                        <p style ={{fontSize: 15}}> Sends every </p>
                        <div style = {{ marginLeft: 15, marginRight: 15}}>
                            <FormControl className={classes.formControl}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    disableUnderline={true}
                                    style = {{maxWidth: 38}}
                                    value={props.cycleNumber}
                                    InputProps={{  disableUnderline: true, className: classes.input}}
                                    placeholder = 'cycle'
                                    onChange={e => props.handleChangeCycleNumber(e.target.value)}
                                >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <FormControl className={classes.formControl}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                style = {{marginLeft: 10}}
                                value={props.cycle}
                                placeholder = 'cycle'
                                onChange={e => props.handleChangeCycle(e.target.value)}
                            >
                                <MenuItem value={'day'}>Day(s)</MenuItem>
                                <MenuItem value={'week'}>Week(s)</MenuItem>
                                <MenuItem value={'month'}>Month(s)</MenuItem>
                            </Select>
                        </FormControl>

                    </Grid>


                    <Grid direction='row' justify = 'flex-start' alignItems='center' container xs = {12} md ={12} lg={12}>

                        {(props.cycle == 'week')
                            ? <Box style={{marginTop: 10}}>
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        value="Sunday"
                                        control={<Checkbox icon={<RadioButtonUncheckedOutlinedIcon/>}
                                                           checkedIcon={<RadioButtonCheckedIcon/>} color="primary"/>}
                                        label="S"
                                        labelPlacement="bottom"
                                        className={classes.checkBox}
                                    />
                                    <FormControlLabel
                                        value="Monday"
                                        control={<Checkbox icon={<RadioButtonUncheckedOutlinedIcon/>} checkedIcon={<RadioButtonCheckedIcon/>} color="primary"/>}
                                        label="M"
                                        labelPlacement="bottom"
                                        className={classes.checkBox}
                                    />
                                    <FormControlLabel
                                        value="Tuesday"
                                        control={<Checkbox icon={<RadioButtonUncheckedOutlinedIcon/>}
                                                           checkedIcon={<RadioButtonCheckedIcon/>} color="primary"/>}
                                        label="T"
                                        labelPlacement="bottom"
                                        className={classes.checkBox}
                                    />
                                    <FormControlLabel
                                        value="Wednesday"
                                        control={<Checkbox icon={<RadioButtonUncheckedOutlinedIcon/>}
                                                           checkedIcon={<RadioButtonCheckedIcon/>} color="primary"/>}
                                        label="W"
                                        labelPlacement="bottom"
                                        className={classes.checkBox}
                                    />
                                    <FormControlLabel
                                        value="Thursday"
                                        control={<Checkbox icon={<RadioButtonUncheckedOutlinedIcon/>}
                                                           checkedIcon={<RadioButtonCheckedIcon/>} color="primary"/>}
                                        label="T"
                                        labelPlacement="bottom"
                                        className={classes.checkBox}
                                    />
                                    <FormControlLabel
                                        value="Friday"
                                        control={<Checkbox icon={<RadioButtonUncheckedOutlinedIcon/>}
                                                           checkedIcon={<RadioButtonCheckedIcon/>} color="primary"/>}
                                        label="F"
                                        labelPlacement="bottom"
                                        className={classes.checkBox}
                                    />
                                    <FormControlLabel
                                        value="Saturday"
                                        control={<Checkbox icon={<RadioButtonUncheckedOutlinedIcon/>}
                                                           checkedIcon={<RadioButtonCheckedIcon/>} color="primary"/>}
                                        label="S"
                                        labelPlacement="bottom"
                                        className={classes.checkBox}
                                    />
                                </FormGroup>
                            </Box>
                            :null
                        }
                        {(props.cycle == 'month')
                            ?
                            <Box display = 'flex' flexDirection = 'row' justify = 'center' alignItems = 'flex-start '>
                                <p style ={{fontSize: 15}}>
                                    Monthly on
                                </p>
                                <Box display = 'flex'
                                     flexDirection = 'row'
                                     justify = 'center'
                                     alignItems = 'flex-start '
                                     style = {{ marginLeft: 15, marginRight: 15}}
                                >
                                    <FormControl className={classes.formControl}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={props.monthlyWeek}
                                            placeholder = 'day of the week'
                                            onChange={e => props.handleChangeMonthlyWeek(e.target.value, 0)}
                                        >
                                            <MenuItem value={1}>1st</MenuItem>
                                            <MenuItem value={2}>2nd</MenuItem>
                                            <MenuItem value={3}>3rd</MenuItem>
                                            <MenuItem value={4}>4th</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <FormControl className={classes.formControl}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={props.monthlyDay}
                                        placeholder = 'day of the week'
                                        onChange={e => props.handleChangeMonthlyDay(e.target.value)}
                                    >
                                        <MenuItem value={'Sunday'}>Sunday</MenuItem>
                                        <MenuItem value={'Monday'}>Monday</MenuItem>
                                        <MenuItem value={'Tuesday'}>Tuesday</MenuItem>
                                        <MenuItem value={'Wednesday'}>Wednesday</MenuItem>
                                        <MenuItem value={'Thursday'}>Thursday</MenuItem>
                                        <MenuItem value={'Friday'}>Friday</MenuItem>
                                        <MenuItem value={'Saturday'}>Saturday</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            :null
                        }


                    </Grid>


                </Grid>
                <Grid style = {{marginTop:60, marginBottom: 0}} xs = {12} md ={12} lg={12} direction = 'column' container>
                    <TextField
                        id="time"
                        label="Send Time"
                        type="time"
                        color = 'secondary'
                        defaultValue="12:00"
                        className={classes.textField}
                        InputLabelProps={{
                             shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }}
                    />
                </Grid>
                <Grid container justify={'center'} alignItems = 'center'>
                <Button
                    style = {{margin: 10}}
                    variant="contained"
                    color = 'primary'
                    className={classes.stickybutton}
                    onClick={()=>console.log('save')}
                >
                    Save
                </Button>
                </Grid>
            </form>
        </Box>
    );
}

const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            padding: 0,
            margin: 0,
            minHeight: 200,
            // maxWidth: 540,
            // backgroundColor: 'white',
        },

        stickybutton: {

                top: "0rem",
                position: "sticky",
                display: 'flex',
                // overflow: 'auto',
                flexDirection: 'column',
                // backgroundColor: 'white',
        },

        box: {
            flexGrow: 1,
            padding: 0,
            display: 'start',
            flexDirection: 'column',
            // margin: 10,
            // marginBottom: 20,
        },

        formControl: {
        },

        textroot: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
                width: '25ch',
            },
        },

        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 150,

        },

        checkBox: {
            height: 25,
            fontSize: 16,
            width: 25,
        },


    }

));

export default AutomationRecurrenceEditor;


{/*<Grid item xs = {12} md ={12} lg={12}>*/}
{/*    <p> Ends </p>*/}

{/*</Grid>*/}

{/*<Grid direction='row' container xs = {12} md ={12} lg={12}>*/}


{/*    <FormControl component="fieldset">*/}
{/*    <RadioGroup aria-label="Ends" name="Ends" value={end} onChange={handleChangeEnd}>*/}
{/*    <FormControlLabel value="Never" control={<Radio />} label="Never" />*/}
{/*    <FormControlLabel value="On" control={<Radio />} label="On" />*/}
{/*    <FormControlLabel value="After" control={<Radio />} label="After" />*/}
{/*    </RadioGroup>*/}
{/*</FormControl>*/}


{/*</Grid>*/}
