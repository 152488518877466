import React, {memo, useEffect} from 'react';
import TodoNode from "../Todo/todoNode"
import Box from "@material-ui/core/Box";
import {Handle} from "react-flow-renderer";
import TextField from "@material-ui/core/TextField/TextField";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import NodeEditor from "../../../../Editor/NodeEditor/nodeEditor";
import {makeStyles} from "@material-ui/core";
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { Rnd } from "react-rnd";
import { BiEdit,BiRctangle, BiMove,BiChevronUp, BiChevronDown, BiText,BiChevronLeft,BiCheckboxChecked,BiListUl,BiUserCircle,BiMessageAltDetail} from "react-icons/bi";
import IconButton from "@material-ui/core/IconButton";
import TitleAndOptions from "../../../NodeUtils/NodeHeaders/titleAndOptions";
import MessagesContainer from "../../../../Messages/Chat/messagesContainer";
import {db} from "../../../../../api/firebase";


let tinycolor = require("tinycolor2");


let global_bg_c = 'white';

export default function ChatNode(props) {
    const classes = useStyles();
    const [done, setDone] = React.useState(props.data.done)
    const [text, setText] = React.useState(props.data.text);
    const [textColor, setTextColor] = React.useState(props.data.textColor);
    const [backgroundColor, setBackGroundColor] = React.useState(props.data.backgroundColor)
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const [size, setSize] = React.useState(props.data.size);
    const [room, setRoom] = React.useState(props.data.room);
    const [messages, setMessages] = React.useState([])


    const getColor = () => {
        //TODO fix hardcode
        let color = tinycolor('black');
        if (color.isDark()){
            return 'white'
        } else {
            return 'black'
        }

    };

    const handleSetEditorState = (editorState) => {
        const contentState = editorState.getCurrentContent();
        let save = JSON.stringify(convertToRaw(contentState));
        setEditorState(editorState)
        props.data.textContent = save;
        // data.save();
    };

    useEffect(() => {

        if (props.data.textContent) {
            let parsed = EditorState.createWithContent(convertFromRaw(JSON.parse(props.data.textContent)))
            setEditorState(parsed);
        }
        global_bg_c = backgroundColor;
    }, []);

    const changeRoom = (room) =>{
        setRoom(room)
        props.data.room = room;
    }


    useEffect(() => {
        console.log('pushhh')
        //TODO: we want to pull only messages that are relevant. The most recent 10 from any tracker in the userTrackerList.
        function getMessages(querySnapshot) {
            let messages= [];
            querySnapshot.forEach(function (doc) {
                messages.push(doc.data())
            });
            let message_sorted = messages.sort( function(a,b) {return a.timeStamp - b.timeStamp});
            setMessages(message_sorted)
        }
        if (props.data.user && room) {
            // console.log('user is present, pulling messages');
            const queryMessages = db.collection('messages').where('roomID', 'in', [room]);
            const unsubscribeMessages = queryMessages.onSnapshot(getMessages, error => console.log(error));
            return () => {
                unsubscribeMessages()
            }
        }
    }, []);


    return (
        <>
            <Box
            className = 'nodrag'
            borderRadius = {8}
                style={{
                margin: 5,
                fontFamily: "Plus Jakarta Sans",
                border: 1,
                borderStyle: "solid",
                borderWidth: 3,
                borderColor: "white",
                overflow: "hidden",
                width: 800,
                backgroundColor: "#242527",
                }}
                // border = {1}
                // borderColor = {data.color}
                display = 'flex'
                flexDirection ='column'
                justifyContent = 'flex-start'

            >
                {/*<TitleAndOptions title = {title} changeTitle = {changeTitle} handleOpenOptions = {handleOpenOptions} />*/}
                <Box           display = 'flex' alignItems = 'center'  style={{height: '10vh',}}> 
                <TextField
                            onChange={(e) => changeRoom(e.target.value)}
                            id="standard-basic"
                            placeholder="Untitled"
                            value={room}
                            fullWidth={true}
                            InputProps={{style: {fontSize: 23, fontFamily: "Plus Jakarta Sans",margin:20, marginRight: 0, fontWeight: 600, zIndex: 5,color: 'white', width: 275}, disableUnderline: true,}}
                />
            </Box>

                <Box
                    className={props.data.className}
                    style = {{overflow:'hidden', fontSize: 18}}
                >
                   
                <MessagesContainer room = {room}  messages={messages.filter(item => item.roomID === room)} channel = {'SolanaMoneyBoys'}  user = {props.data.user}/>
                </Box>

                
            

            </Box>




        </>



    );
};


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },

    cont1 : {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    },

    cont2 : {
        height: '100%',
        width: '100%',
        overflow: 'auto',
        paddingRight: 20,
    },
    box:{
        padding: 0,
        display: 'start',
        overflow: 'auto',
        flexDirection: 'column',
    },

    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },

    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },

    fixedHeight: {
        height: 350,
    },
    popover: {
    },

    toolbar: {
        fontSize: 12,
        backgroundColor: global_bg_c,


    }
}));