import React, {memo, useEffect} from 'react';
import Box from "@material-ui/core/Box";
import {Handle} from "react-flow-renderer";
import TextField from "@material-ui/core/TextField/TextField";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import NodeEditor from "../../../Editor/NodeEditor/nodeEditor";
import {makeStyles} from "@material-ui/core";
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { Rnd } from "react-rnd";
import { BiEdit,BiRctangle, BiMove,BiChevronUp, BiChevronDown, BiText,BiChevronLeft,BiCheckboxChecked,BiListUl,BiUserCircle,BiMessageAltDetail} from "react-icons/bi";
import IconButton from "@material-ui/core/IconButton";
import TitleAndOptions from "../../NodeUtils/NodeHeaders/titleAndOptions";
let tinycolor = require("tinycolor2");


let global_bg_c = 'white';

export default function ProgrammedNode(props) {
    const classes = useStyles();
    const [done, setDone] = React.useState(props.data.done)
    const [text, setText] = React.useState(props.data.text);
    const [textColor, setTextColor] = React.useState(props.data.textColor);
    const [backgroundColor, setBackGroundColor] = React.useState(props.data.backgroundColor)
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const [size, setSize] = React.useState(props.data.size);


    const getColor = () => {
        //TODO fix hardcode
        let color = tinycolor('black');
        if (color.isDark()){
            return 'white'
        } else {
            return 'black'
        }

    };

    const handleSetEditorState = (editorState) => {
        const contentState = editorState.getCurrentContent();
        let save = JSON.stringify(convertToRaw(contentState));
        setEditorState(editorState)
        props.data.textContent = save;
        // data.save();
    };

    useEffect(() => {

        if (props.data.textContent) {
            let parsed = EditorState.createWithContent(convertFromRaw(JSON.parse(props.data.textContent)))
            setEditorState(parsed);
        }
        global_bg_c = backgroundColor;
    }, []);


    return (
        <>
            <embed src="https://vclock.com/timer/" style={{width:1000, height: 350}} />


        </>



    );
};


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },

    cont1 : {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    },

    cont2 : {
        height: '100%',
        width: '100%',
        overflow: 'auto',
        paddingRight: 20,
    },
    box:{
        padding: 0,
        display: 'start',
        overflow: 'auto',
        flexDirection: 'column',
    },

    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },

    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },

    fixedHeight: {
        height: 350,
    },
    popover: {
    },

    toolbar: {
        fontSize: 12,
        backgroundColor: global_bg_c,


    }
}));
