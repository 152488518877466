import React, { memo, useEffect } from "react";
import TodoNode from "../Todo/todoNode";
import Box from "@material-ui/core/Box";
import { Handle } from "react-flow-renderer";
import TextField from "@material-ui/core/TextField/TextField";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import NodeEditor from "../../../../Editor/NodeEditor/nodeEditor";
import { Divider, makeStyles } from "@material-ui/core";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Rnd } from "react-rnd";
import {
  BiEdit,
  BiRctangle,
  BiMove,
  BiChevronUp,
  BiChevronDown,
  BiText,
  BiChevronLeft,
  BiCheckboxChecked,
  BiListUl,
  BiUserCircle,
  BiMessageAltDetail,
} from "react-icons/bi";
import IconButton from "@material-ui/core/IconButton";
import TitleAndOptions from "../../../NodeUtils/NodeHeaders/titleAndOptions";
import { PowerOffSharp } from "@material-ui/icons";
import { db, storage } from "../../../../../api/firebase";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

let tinycolor = require("tinycolor2");

let global_bg_c = "white";

const data = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

export default function LandMetricsNode(props) {
  const classes = useStyles();
  const [done, setDone] = React.useState(props.data.done);
  const [text, setText] = React.useState(props.data.text);
  const [textColor, setTextColor] = React.useState(props.data.textColor);
  const [backgroundColor, setBackGroundColor] = React.useState(
    props.data.backgroundColor
  );
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  const [size, setSize] = React.useState(props.data.size);
  const [img, setImg] = React.useState(props.data.img);
  const [editing, setEditing] = React.useState(false);
  const [users, setUsers] = React.useState([]);

  const handleFireBaseUpload = async (e, imageAsFile) => {
    e.preventDefault();
    console.log("start of upload");
    if (imageAsFile === "") {
      console.error(`not an image, the image file is a ${typeof imageAsFile}`);
      return;
    }
    if (imageAsFile) {
      const uploadTask = storage
        .ref(`/images/${imageAsFile.name}`)
        .put(imageAsFile);
      //initiates the firebase side uploading
      uploadTask.on(
        "state_changed",
        (snapShot) => {
          //takes a snap shot of the process as it is happening
          console.log(snapShot);
        },
        (err) => {
          //catches the errors
          console.log(err);
        },
        async () => {
          // gets the functions from storage refences the image storage in firebase by the children
          // gets the download url then sets the image from firebase as the value for the imgUrl key:
          storage
            .ref("images")
            .child(imageAsFile.name)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              setImg((prevObject) => ({ ...prevObject, imgUrl: fireBaseUrl }));
            });
        }
      );
    }
  };

  const handleImageAsFile = async (e) => {
    setEditing(true);
    const image = e.target.files[0];
    // await setImageAsFile(image);
    await handleFireBaseUpload(e, image);
    // setIsLoadingImage(false);
  };
  props.data.img = img;

  const handleSave = () => {
    console.log(img);
  };

  const getColor = () => {
    //TODO fix hardcode
    let color = tinycolor("black");
    if (color.isDark()) {
      return "white";
    } else {
      return "black";
    }
  };

  const handleSetEditorState = (editorState) => {
    const contentState = editorState.getCurrentContent();
    let save = JSON.stringify(convertToRaw(contentState));
    setEditorState(editorState);
    props.data.textContent = save;
    // data.save();
  };

  async function getMeData() {
    try {
      let response = await fetch(
        "https://api-mainnet.magiceden.io/rpc/getCollectionEscrowStats/solana_money_boys"
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(async () => {
    const projectRef = db.collection("users");
    // const snapshot = await projectRef.get();
    const snap = projectRef.onSnapshot(
      (querySnapshot) => {
        let users = [];
        querySnapshot.forEach((doc) => {
          users.push(doc.data());
        });
        setUsers(users);
        // ...
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );
  }, []);

  return (
    <>
      <Box
        borderRadius={10}
        className = 'nodrag'
        style={{
          margin: 5,
          fontFamily: "Plus Jakarta Sans",
          border: 1,
          borderStyle: "solid",
          borderWidth: 3,
          borderColor: "white",
          overflow: "hidden",
          width: 500,
          backgroundColor: "#242527",
        }}
        // border = {1}
        // borderColor = {data.color}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
        <p
          style={{ color: "white", fontWeight: 500, fontSize: 30, margin: 20 }}
        >
          {" "}
          Signup Metrics{" "}
        </p>
        

        <Divider style={{ backgroundColor: "white" }} />

        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={500}
            height={400}
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="uv"
              stroke="#8884d8"
              fill="#8884d8"
            />
          </AreaChart>
        </ResponsiveContainer>

        <Box
          className={props.data.className}
          style={{
            overflow: "hidden",
            margin: 20,
            fontSize: 23,
            fontWeight: 600,
            color: "white",
            fontFamily: "Plus Jakarta Sans",
          }}
        >
          <p> Total Users: {users.length}</p>
          <p> Coins Claimed: {users.length * 10000} </p>
        </Box>

        {/*<Box borderLeft = {1} borderColor = {data.color} display ='flex' flexDirection = 'column ' style = {{flex:1}}>*/}
        {/*    <BiMove style = {{margin: 5, color: data.color}} size = {15} />*/}

        {/*    <IconButton onClick = {fold} style ={{margin:0, padding: 0}} >*/}
        {/*    {  isFolded*/}
        {/*        ?<BiChevronUp style = {{margin: 5, color: data.color}} size = {17} />*/}
        {/*        : <BiChevronDown  style = {{margin: 5, color: data.color}} size = {17} />*/}

        {/*    }*/}
        {/*    </IconButton>*/}

        {/*</Box>*/}
{/* = */}
      </Box>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  cont1: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },

  cont2: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    paddingRight: 20,
  },
  box: {
    padding: 0,
    display: "start",
    overflow: "auto",
    flexDirection: "column",
  },

  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },

  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  fixedHeight: {
    height: 350,
  },
  popover: {},

  toolbar: {
    fontSize: 12,
    backgroundColor: global_bg_c,
  },
}));

// {img ?
//     <img style = {{width:400, height:200,border:1, borderStyle:'solid', borderWidth: 3,borderColor:'white', objectFit:'cover', overflow:'hidden',}} src= {img.imgUrl}></img>

//     :
//         <>
//         <input className={classes.input} id="contained-button-file" accept="image/*" type ='file' onChange={handleImageAsFile} />
//         <label htmlFor="contained-button-file">
//         {/* <Button variant="contained"> Upload </Button> */}
//         </label>

//     </>

// }
