import React, {memo, useEffect} from 'react';
import Box from "@material-ui/core/Box";
import {Handle} from "react-flow-renderer";
import TextField from "@material-ui/core/TextField/TextField";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import {makeStyles} from "@material-ui/core";
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { Rnd } from "react-rnd";
import { BiEdit,BiRctangle, BiMove,BiChevronUp, BiChevronDown, BiText,BiChevronLeft,BiCheckboxChecked,BiListUl,BiUserCircle,BiMessageAltDetail} from "react-icons/bi";
import IconButton from "@material-ui/core/IconButton";
import {db, storage} from "../../../../api/firebase"
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";


import Button from "@material-ui/core/Button";


let tinycolor = require("tinycolor2");



let global_bg_c = 'white';

export default function ManualAvatar(props) {
    const classes = useStyles();
    const [done, setDone] = React.useState(props.data.done)
    const [text, setText] = React.useState(props.data.text);
    const [img, setImg] = React.useState(props.data.img)
    const [textColor, setTextColor] = React.useState(props.data.textColor);
    const [backgroundColor, setBackGroundColor] = React.useState(props.data.backgroundColor)
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const [size, setSize] = React.useState(props.data.size);
    const [editing, setEditing] = React.useState(false)
    
    const changeName = (name) => {
        props.data.name = name
    };

    const handleFireBaseUpload = async (e, imageAsFile) => {
        e.preventDefault();
        console.log('start of upload');
        if(imageAsFile === '' ) {
            console.error(`not an image, the image file is a ${typeof(imageAsFile)}`)
            return;
        }
        if (imageAsFile) {
            const uploadTask = storage.ref(`/images/${imageAsFile.name}`).put(imageAsFile)
            //initiates the firebase side uploading
            uploadTask.on('state_changed',
                (snapShot) => {
                    //takes a snap shot of the process as it is happening
                    console.log(snapShot)
                }, (err) => {
                    //catches the errors
                    console.log(err)
                }, async () => {
                    // gets the functions from storage refences the image storage in firebase by the children
                    // gets the download url then sets the image from firebase as the value for the imgUrl key:
                    storage.ref('images').child(imageAsFile.name).getDownloadURL()
                        .then(fireBaseUrl => {
                            setImg(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
                        })
                })
        }
    };



    const handleImageAsFile = async(e) => {
        setEditing(true)
        const image = e.target.files[0];
        // await setImageAsFile(image);
        await handleFireBaseUpload(e, image);
        // setIsLoadingImage(false);
    };
    props.data.img = img



   
    const handleSave = () => {
        console.log(img)
    };

    useEffect(() => {

        if (props.data.textContent) {
            let parsed = EditorState.createWithContent(convertFromRaw(JSON.parse(props.data.textContent)))
            setEditorState(parsed);
        }
        global_bg_c = backgroundColor;
    }, []);


    return (
        <Grid item xs={0} md={0} lg={0}>
        <Box display = 'flex' justifyContent = 'center' alignItems = 'center' flexDirection = 'column'> 
        <Box className={props.data.className} borderColor = {'white'} style={{ boxShadow: `0px 1px 3px #7F7E80`, margin:-2, width:62.5}} border={3} borderColor={props.data.color} borderRadius={50}>
            <Avatar style = {{pointerEvents: 'none'}} alt=""  onClick = {()=>console.log('click')} src={img ? img.imgUrl : ''} className={classes.large}/>
        </Box>
        {/*<Handle*/}

        {/*    type="source"*/}
        {/*    id = 'j'*/}
        {/*    position="left"*/}
        {/*    style={{ zIndex: 12, backgroundColor: data.color,boxShadow: "0px 2px 4px #C5C5C5", }}*/}
        {/*    // onConnect={(params) => console.log('handle onConnect', params)}*/}
        {/*/>*/}
        {/*<Handle*/}
        {/*    type="source"*/}
        {/*    position="right"*/}
        {/*    id="z"*/}
        {/*    style={{  zIndex: 12, borderRadius: 100,boxShadow: "0px 0px 4px #C5C5C5",backgroundColor:data.color }}*/}
        {/*/>*/}

        {/*<Handle*/}
        {/*    type = 'source'*/}
        {/*    id="a"*/}
        {/*    position="top"*/}
        {/*    style={{  zIndex: 12,  borderRadius: 100,boxShadow: "0px 0px 4px #C5C5C5",backgroundColor:data.color }}*/}
        {/*/>*/}
        <Box
                style = {{margin: 5}}
                // border = {1}
                // borderColor = {data.color}
                display = 'flex'
                flexDirection ='column'
                justifyContent = 'flex-start'

            >
            {img ? 

                null

            :
            <>
            
            <input className={classes.input} id="contained-button-file" accept="image/*" type ='file' onChange={handleImageAsFile} />
            <label htmlFor="contained-button-file">
            {/* <Button variant="contained"> Upload </Button> */}
            </label>

            </>

        

            }
             
             
            </Box>

            {/* <TextField
                defaultValue={props.data.name}
                inputStyle={{ textAlign: 'center' }}

                placeholder={'add name'}
                onChange={e=> changeName(e.target.value)}
                // type="number"
                // fullWidth={true}
                InputProps={{style: {width: 70, marginTop: -10,fontSize: 11,fontWeight: 600, color:'white', textAlign: 'center' }, textAlign: 'center', min:0, disableUnderline: true,}}

            /> */}
            </Box>

            {/* <Handle
            type="source"
            id = 'k'
            position="bottom"
            style={{ zIndex: 12,boxShadow: `0px 3px 10px rgba(0, 0, 0, 0.15)`}}
            // onConnect={(params) => console.log('handle onConnect', params)}
        /> */}
           



            </Grid>

        



    );
};


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },

    cont1 : {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    },

    cont2 : {
        height: '100%',
        width: '100%',
        overflow: 'auto',
        paddingRight: 20,
    },
    box:{
        padding: 0,
        display: 'start',
        overflow: 'auto',
        flexDirection: 'column',
    },

    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },

    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },

    fixedHeight: {
        height: 350,
    },
    popover: {
    },

    toolbar: {
        fontSize: 12,
        backgroundColor: global_bg_c,


    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));
