import React, { Component } from "react";
import {BrowserRouter as Router, HashRouter, Switch, Route, Link, Redirect,useParams} from "react-router-dom";
import home from './views/landerViews/home';
import Dashboard from './views/core/dashboard'
import signup from './views/authentication/signup';
import Feedbox from './xdeprecated/Old/feedbox'
import login from './views/authentication/login';
import lander from './views/landerViews/lander'
import {auth} from './api/firebase';
import './styles.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {db} from "./api/firebase";
import firebase from 'firebase/app';
import Box from "@material-ui/core/Box";
import mbox from "./assets/images/mbox.png"
import "@fontsource/plus-jakarta-sans"; // Defaults to weight 400.



import DashboardContainer from "./views/core/dashboardContainer";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#252525'
        },
        secondary:{
            main:'#1F69FC'
        }
    }
});

export default class App extends Component {
    constructor() {
        super();
        this.state = {
            authenticated: false,
            loading: true,
      };
    }
    async componentDidMount() {
      await auth().onAuthStateChanged(user => {
          console.log(user);
        if (user) {
            this.setState({
                authenticated: true,
                loading: false,

          });
        } else {
            console.log(this);
            this.setState({
            authenticated: false,
            loading: false
          });
        }
      });
    }

    render() {
      return this.state.loading === true ? (
        <Box style = {{height: '100vh', width: '110vw', display:'flex', backgroundColor:'#363638', justifyContent:'center', alignItems:'center'}}> 
        
        <div style = {{color:'white'}} className="spinner-border text-success" role="status">
        {/* <img src ={mbox} style = {{width:'20vw'}}/> */}
        

        <p style = {{textAlign:'center'}}> loading..</p>
        </div>
    </Box>
          
      ) : (
              <MuiThemeProvider theme={theme}>

          <Router>
            <Switch>
              <Route
                //   exact 
                  path="/"
                //   component={DashboardContainer}
                  render={(props) => <DashboardContainer authenticated = {this.state.authenticated} {...props} />}

              />
                <PublicRoute
                    path="/signup"
                    root = {this.state.root}
                    authenticated={this.state.authenticated}
                    component={signup}
                />
                {/* <PublicRoute
                    path="/login"
                    root = {this.state.root}
                    authenticated={this.state.authenticated}
                    component={login}
                />
                <PrivateRoute
                    path="/board"
                    authenticated={this.state.authenticated}
                    render={(props) => <DashboardContainer {...props} />}
                    component={DashboardContainer}
                />

                <PrivateRoute
                  path="/feed"
                  authenticated={this.state.authenticated}
                  component={DashboardContainer}
              /> */}



            </Switch>
          </Router>
          </MuiThemeProvider>


    );
    }
  }

function PrivateRoute({ component: Component, authenticated, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => authenticated === true
                ? <Component {...props} />
                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
        />
    )
}

function PublicRoute({ component: Component,authenticated, ...rest }) {
    return (
        <Route
            {...rest}
            render={props =>
                authenticated === false ? (
                    <Component {...props} />
                ) : (
                    <Redirect to= {`/board`} />
                )
            }
        />
    );
}


