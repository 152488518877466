import React, { memo, useEffect } from "react";
import TodoNode from "../Todo/todoNode";
import Box from "@material-ui/core/Box";
import { Handle } from "react-flow-renderer";
import TextField from "@material-ui/core/TextField/TextField";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import NodeEditor from "../../../../Editor/NodeEditor/nodeEditor";
import { makeStyles } from "@material-ui/core";
import Badge from "@material-ui/core/Badge/Badge";
import Button from "@material-ui/core/Button/Button";
import { db, storage } from "../../../../../api/firebase";
import { Divider } from "@material-ui/core";

import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Rnd } from "react-rnd";
import {
  BiEdit,
  BiRctangle,
  BiMove,
  BiChevronUp,
  BiChevronDown,
  BiText,
  BiChevronLeft,
  BiCheckboxChecked,
  BiListUl,
  BiUserCircle,
  BiMessageAltDetail,
} from "react-icons/bi";
import IconButton from "@material-ui/core/IconButton";
import TitleAndOptions from "../../../NodeUtils/NodeHeaders/titleAndOptions";
import { PowerOffSharp } from "@material-ui/icons";
import { TiDivideOutline } from "react-icons/ti";
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer } from "recharts";
import { getPanelId } from "@material-ui/lab";
let tinycolor = require("tinycolor2");

let global_bg_c = "white";

export default function VoteNode(props) {
  const classes = useStyles();
  const [done, setDone] = React.useState(props.data.done);
  const [text, setText] = React.useState(props.data.text);
  const [textColor, setTextColor] = React.useState(props.data.textColor);
  const [backgroundColor, setBackGroundColor] = React.useState(
    props.data.backgroundColor
  );
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  const [size, setSize] = React.useState(props.data.size);
  const [selected, setSelected] = React.useState(null);
  const [voted, setVoted] = React.useState(props.data.user.voted);
  const [results, setResults] = React.useState(props.data.results);

  const getColor = () => {
    //TODO fix hardcode
    let color = tinycolor("black");
    if (color.isDark()) {
      return "white";
    } else {
      return "black";
    }
  };
  const didVote = () => {
    return false;
  };
  const submitVote = () => {
    setVoted(true);
    if (selected === "Monke") {
      props.data.results.monke = props.data.results.monke + 1;
    } else if (selected === "Thug") {
      props.data.results.thug = props.data.results.thug + 1;
    } else {
      return;
    }

    var userRef = db.collection("users").doc(props.data.user.email);
    // Set the "capital" field of the city 'DC'
    return userRef
      .update({
        voted: true,
      })
      .then(() => {
        console.log("Document successfully updated!");
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };

  const getPie = (res) => {
    return [
      { name: "ThugDAO", value: res.thug },
      { name: "MonkeDAO", value: res.monke },
    ];
  };

  const handleSetEditorState = (editorState) => {
    const contentState = editorState.getCurrentContent();
    let save = JSON.stringify(convertToRaw(contentState));
    setEditorState(editorState);
    props.data.textContent = save;
    // data.save();
  };

  useEffect(() => {
    if (props.data.textContent) {
      let parsed = EditorState.createWithContent(
        convertFromRaw(JSON.parse(props.data.textContent))
      );
      setEditorState(parsed);
    }
    global_bg_c = backgroundColor;
  }, []);

  return (
    <>
      <Badge   badgeContent={1} style={{}} color="secondary">
        <Box
          borderRadius={10}
          style={{
            fontFamily: "Plus Jakarta Sans",
            border: 1,
            borderStyle: "solid",
            borderWidth: 3,
            borderColor: "white",
            overflow: "hidden",
            width: 500,
            paddingBottom: 10,
            backgroundColor: "#242527",
          }}
          // border = {1}
          // borderColor = {data.color}
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
        >
          {/*<TitleAndOptions title = {title} changeTitle = {changeTitle} handleOpenOptions = {handleOpenOptions} />*/}

          <Box
            className={props.data.className}
            style={{ overflow: "hidden", fontSize: 18 }}
          >
            <Editor
              editorState={editorState}
              readOnly={!props.data.authenticated}
              toolbarClassName="toolbarClassName"
              toolbarOnFocus
              toolbarHidden={!props.data.authenticated}
              // readOnly = {data.className === 'nodrag' ? false : true}
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={handleSetEditorState}
              editorStyle={{ width: 400, margin: 15, color: "white" }}
              toolbarStyle={{
                backgroundColor: "white",
                zIndex: 30,
                boxShadow: "0px 0px 4px #C5C5C5",
                borderRadius: 10,
                marginTop: -70,
                width: 320,
                borderColor: backgroundColor,
                position: "absolute",
              }}
              toolbar={{
                options: [
                  "fontSize",
                  "list",
                  "colorPicker",
                  "link",
                  "emoji",
                  "history",
                ],
                colorPicker: {
                  className: undefined,
                  component: undefined,
                  popupClassName: undefined,
                  colors: [
                    "#FFFFFF",
                    "#000000",
                    "#6E80EF",
                    "#7948FB",
                    "#363144",
                    "#828088",
                    "#3CCD94",
                    "#4F89CF",
                    "#E56A51",
                    "#FA4420",
                    "rgb(41,105,176)",
                    "rgb(85,57,130)",
                    "rgb(40,50,78)",
                    "rgb(0,0,0)",
                    "rgb(247,218,100)",
                    "rgb(251,160,38)",
                    "rgb(235,107,86)",
                    "rgb(226,80,65)",
                    "rgb(163,143,132)",
                  ],
                },

                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
              }}
            />
          </Box>
          <Divider
            style={{
              color: "white",
              borderColor: "white",
              marginTop: -10,
              backgroundColor: "white",
            }}
          />

          {voted && props.data.results ? (
            <Box
              style={{ color: "white", fontSize: 20, fontWeight: 500 }}
              flexDirection="column"
              display="flex"
              justifyContent="center"
            >
              <p
                style={{
                  color: "white",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: 22,
                  margin: 10,
                  marginTop: 20,
                }}
              >
                {" "}
                RESULTS:{" "}
              </p>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-around"
              >
                <p> ThugDAO: {23} </p>
                <p> MonkeDAO: {20} </p>
              </Box>

              <PieChart width={500} height={300}>
                <Pie
                  dataKey="value"
                  isAnimationActive={false}
                  data={getPie(results)}
                  cx="50%"
                  cy="50%"
                  outerRadius={120}
                  fill="#0E70FF"
                  label
                />
                <Tooltip />
              </PieChart>
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" style={{ width: 500 }}>
              <Box
                display="flex "
                style={{ width: 500, marginTop: 10 }}
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  onClick={() => setSelected("Thug")}
                  style={{
                    margin: 10,
                    width: 200,
                    color: "white",
                    fontSize: 20,
                    backgroundColor:
                      selected === "Thug" ? "#0E70FF" : "#24417C",
                  }}
                  variant="contained"
                  color="white"
                >
                  ThugDAO
                </Button>

                <Button
                  onClick={() => setSelected("Monke")}
                  style={{
                    width: 200,
                    color: "white",
                    fontSize: 20,

                    margin: 10,
                    backgroundColor:
                      selected === "Monke" ? "#0E70FF" : "#24417C",
                  }}
                  variant="contained"
                  color="white"
                >
                  MonkeDAO
                </Button>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  onClick={() => submitVote()}
                  style={{
                    width: 200,
                    color: "white",
                    fontSize: 20,

                    margin: 20,
                    color: "white",
                    backgroundColor: "#0E70FF",
                  }}
                  variant="contained"
                  color="white"
                >
                  Submit Vote
                </Button>
              </Box>
            </Box>
          )}

          {/* <Handle
            type="source"
            id="k"
            position="bottom"
            style={{
              zIndex: 12,
              backgroundColor: props.data.color,
              boxShadow: `0px 3px 10px rgba(0, 0, 0, 0.15)`,
            }}
            // onConnect={(params) => console.log('handle onConnect', params)}
          /> */}
        </Box>
      </Badge>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  cont1: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },

  cont2: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    paddingRight: 20,
  },
  box: {
    padding: 0,
    display: "start",
    overflow: "auto",
    flexDirection: "column",
  },

  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },

  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  fixedHeight: {
    height: 350,
  },
  popover: {},

  toolbar: {
    fontSize: 12,
    backgroundColor: global_bg_c,
  },
}));
