import React, {useCallback, useEffect, useRef} from 'react';
import ReactFlow, {addEdge, Background, Controls, MiniMap, ReactFlowProvider, removeElements, updateEdge, useStoreState, useZoomPanHelper,useStore,} from 'react-flow-renderer';
import Box from "@material-ui/core/Box";
import Button from '@material-ui/core/Button';
import PuffLoader from "react-spinners/PuffLoader";
import FlowController from "../Controllers/flowController"
import LabelNode from "../../Nodes/NodeList/NonActive/Label/labelNode";
import TodoNode from "../../Nodes/NodeList/NonActive/Todo/todoNode";
import {saveFlow, saveViewPort} from "../../../api/firestore";
import FeedController from "../Controllers/feedController";
import BitCoinGifNode from "../../Nodes/NodeList/NonActive/xDeprecated/ScrapNodeList/bitCoinGifNode"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import NoteNode from "../../Nodes/NodeList/NonActive/Note/noteNode";
import WebPageNode from "../../Nodes/NodeList/NonActive/xDeprecated/ScrapNodeList/webPageNode";
import CharacterNode from "../../Nodes/NodeList/NonActive/xDeprecated/ScrapNodeList/characterNode";
import AvatarNode from "../../Nodes/NodeList/NonActive/Avatars/avatarNode"
import ButtonNode from "../../Nodes/NodeList/NonActive/Button/buttonNode"
import DocumentNode from "../../Nodes/NodeList/NonActive/Document/documentNode"
import CalendarNode from "../../Nodes/NodeList/NonActive/xDeprecated/ScrapNodeList/calendarNode"
// import KanBanNode from "../Nodes/NodeList/List/kanbanNode"
import {selectNode} from "../../Nodes/NodeSelector/nodeSelector";
import GraphNode from "../../Nodes/NodeList/NonActive/Graphs/graphNode"
import FolderNode from "../../Nodes/NodeList/NonActive/Folder/folderNode"
import BoxNode from "../../Nodes/NodeList/NonActive/Board/boxNode"
import ThoughtNode from "../../Nodes/NodeList/Active/Thought/thoughtNode"
import CardNode from "../../Nodes/NodeList/NonActive/Card/cardNode"
import ReportNode from "../../Nodes/NodeList/NonActive/xDeprecated/ScrapNodeList/investorReportNode"
import MetricNode from "../../Nodes/NodeList/NonActive/Number/metricNode";
import StandardNode from "../../Nodes/NodeList/NodeContainers/standardContainer";
import AppNode from "../../Nodes/NodeList/NodeContainers/appContainer";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import DividerNode from "../../Nodes/NodeList/NonActive/xDeprecated/dividerNode"
import ProjectHeader from "../../Headers/projectHeader";
import clsx from 'clsx';
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Rooms from "../../Messages/Rooms/rooms";
import Zoom from './zoom';
import ProgrammedNode from '../../Nodes/NodeList/NonActive/programmedNode';
import ListNode from '../../Nodes/NodeList/NonActive/List/listNode';
import manualAvatar from '../../Nodes/NodeList/NonActive/manualAvatar';
import SignupNode from '../../Nodes/NodeList/NonActive/Signup/signupNode';
import LandMetricsNode from "../../Nodes/NodeList/NonActive/LandMetrics/landMetricsNode"
import ChatNode from "../../Nodes/NodeList/NonActive/Chat/chatNode"

import MarketMetricsNode from "../../Nodes/NodeList/NonActive/MarketMetrics/marketMetricsNode"
import VoteNode from '../../Nodes/NodeList/NonActive/DaoVote/voteNode';



let timerID = null;
const reset = [
    { id: '1', data: { label: 'Node 1' }, position: { x: 250, y: 5 } },
    // you can also pass a React component as a label
    { id: '2', data: { label: <div>Node 2</div> }, position: { x: 100, y: 100 } },
    { id: 'e1-2', source: '1', target: '2', animated: true },
];

const nodeTypes = {
    cardNode: CardNode,
    chatNode: ChatNode,
    voteNode: VoteNode,
    signupNode: SignupNode,
    landMetricsNode: LandMetricsNode,
    marketMetricsNode: MarketMetricsNode,
    thoughtNodes: ThoughtNode,
    webPageNodes: WebPageNode,
    labelNodes: LabelNode,
    noteNodes: NoteNode,
    programmedNodes: ProgrammedNode,
    todoNodes: TodoNode,
    avatarNodes: AvatarNode,
    buttonNodes: ButtonNode,
    calendarNodes: CalendarNode,
    graphNodes: GraphNode,
    boxNodes: BoxNode,
    folderNodes: FolderNode,
    characterNode: CharacterNode,
    bitCoinGifNodes: BitCoinGifNode,
    documentNodes: DocumentNode,
    metricNodes: MetricNode,
    listNodes: ListNode,
    reportNodes: ReportNode,
    appNodes: AppNode,
    standardNodes: StandardNode,
    dividerNodes: DividerNode,
    manualAvatar: manualAvatar,
};

const getNodeId = () => `node_${+new Date()}`;

function BaseChart(props) {
    const [elements,setElements] = React.useState([]);
    const [id, setID] = React.useState(500);
    const [rfInstance, setRfInstance] = React.useState(null);
    const [saving, setSaving] = React.useState(false);
    const [buttonStyle, setButtonStyle] = React.useState({borderColor: '#545359'});
    const [open, setOpen] = React.useState(false);
    const [elementsToRemove, setElementsToRemove] = React.useState(null);
    const [isChatOpen,openChat] = React.useState(false);
    const reactFlowWrapper = useRef(null);
    const [isAdding,setIsAdding] = React.useState(false)
    const [isWorking,setIsWorking] = React.useState(false)
    const [refreshKey, setRefreshKey] = React.useState(' ');
    const [reactFlowInstance, setReactFlowInstance] = React.useState(null);
    const handleClickOpen = () => {setOpen(true);};
    const handleClose = () => {setOpen(false);};
    const { transform } = useZoomPanHelper();
    const classes = useStyles();
    const store = useStore();
    const { zoomIn, zoomOut, setCenter } = useZoomPanHelper();
    const [width, setWidth] = React.useState(window.innerWidth)

    function handleWindowSizeChange() {
            setWidth(window.innerWidth);
    }

    useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
    }, []);

    let isMobile = (width <= 768);

    const onSave = () => {
        if (rfInstance || elements.length > 0) {
            const flow = rfInstance.toObject();
            saveFlow(props.channel.channelID, flow);
            let position = flow.position;
            let zoom = flow.zoom;
            let updatedProjectIDs = props.user.projectIDs;
            updatedProjectIDs[props.channel.channelID].viewPort = position;
             updatedProjectIDs[props.channel.channelID].zoom = zoom;
            saveViewPort(updatedProjectIDs, props.user.email)
            setIsWorking(false)
        }
    };
    const onRestore = useCallback((flow) => {
        const restoreFlow = async () => {
            if (flow) {
                const [x = 0, y = 0] = flow.position;
                setElements(flow.elements || []);
                transform({ x, y, zoom: flow.zoom || 0 });
            }
        };
        restoreFlow(flow);
    }, [setElements, transform]);

    const addNode = (type, position) => {
        let currentElements = elements.slice();
        let id = getNodeId();
        let node = selectNode(type,id,props.user,props.channel.color,position, props.authenticated);
        let nID = id + 1;
        setID(nID);
        currentElements.push(node);
        setElements(currentElements);
        triggerAutoSave()
    };

    //TODO SET NEW ELEMENTS
    const onTextChange = (text, id,) => {
        let prevElements = elements.slice();
        for (let node of prevElements ) {
            if (node.id === id) {
                node.data.text = text
            }
        }
        if (prevElements.length > 0) {
            setElements(prevElements)
        }
        triggerAutoSave()
    };
    const onElementsRemove = (elementsToRemove) => {
        setElementsToRemove(elementsToRemove);
        handleClickOpen()
    };
    const confirmElementsRemoveBase = (id, )=>{
        console.log(id, elements);
        handleClose();
        setElements((els) => removeElements(elementsToRemove, els));
        setElementsToRemove(null);
        triggerAutoSave();

    };

    const confirmElementsRemove = (id, )=>{
        if (id) {
            console.log(id, elements);
            handleClose();
            let elementToRemove = null;

            for (let node of elements) {
                if (node.id && node.id === id ) {
                    elementToRemove = node;
                }
            }
            if (elementToRemove!==null) {
                setElements((els) => removeElements([elementToRemove], els));
            }
            setElementsToRemove(null);
            triggerAutoSave();
        }  else {
        }
    };

    const onEdgeUpdate = (oldEdge, newConnection) => {
        setElements((els) => updateEdge(oldEdge, newConnection, els));
        triggerAutoSave()
    };

    const onNodeDragStop = (event, node) => {

        triggerAutoSave()
    };
    const onElementClick = () => {
        triggerAutoSave()
    };

    const keyPress = ()=> {
    }

    const onConnect = (params) =>  {
        params.animated = true;
        setElements((els) =>
            addEdge(params, els)
        );
    };
    const triggerAutoSave = async () => {
        if (true) { 
        setSaving(true);
        if (timerID) {
            clearTimeout(timerID);
            timerID = null;
        }

        timerID = setTimeout(() => {
            onSave();
            setSaving(false);
            console.log("finished saving")
        }, 2500)
    }
    };

    const onLoad = (reactFlowInstance) => {
        setRfInstance(reactFlowInstance)
    };

    const onDragOver = (event) => {
        event.preventDefault();
        console.log('drag')
        event.dataTransfer.dropEffect = 'move';
    };

    const onDrop = async (event) => {
        event.preventDefault();
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const type = event.dataTransfer.getData('application/reactflow');
        const position = rfInstance.project({
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top,
        });
        let id = getNodeId();
        const newNode = await selectNode(type,id,props.user,props.channel.color,position);
        if (newNode && newNode.data) { 
        newNode.data.delete = confirmElementsRemove;
        newNode.data.user = props.user;

        if (type === 'boxfront' || type ==='box'){
            let elems = elements.slice();
            if (newNode.layer === 0) {
                elems.unshift(newNode);
            } else {
                for (let i = 0; i < elems.length; i++){
                    if (elems[i].type !== 'boxNodes'){
                        elems.splice(i,0,newNode);
                        break;
                    }
                }
            }
            setElements(elems);
        } else  {
            setElements((es) => es.concat(newNode));
        }
        }
        
    };

    const reset = () => {
        let f = JSON.parse(props.channel.flow);
        let dbElements = f.elements;
        for (let node of dbElements){
            if (node.data) {
                node.data.delete = confirmElementsRemove;
                node.data.user = props.user;
            }
        }
        setElements(dbElements)
    }

    const onNodeDoubleClick = async(event, node) => {
        //TODO UNBLOCK
        if (!isWorking && false) {
            event.preventDefault();
            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            // const type = event.dataTransfer.getData('application/reactflow');
            const position = rfInstance.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            });
            let id = getNodeId();
            const newNode = await selectNode('thought', id, props.user, props.channel.color, position);
            newNode.data.delete = confirmElementsRemove;
            newNode.data.user = props.user;
            setElements((es) => es.concat(newNode));
            setIsWorking(true)
        }

    };

    const onBaseClick = async(event) => {
        if (isAdding){
            setIsAdding(false)
            event.preventDefault();
            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            // const type = event.dataTransfer.getData('application/reactflow');
            const position = rfInstance.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            });
            let id = getNodeId();
            const newNode = await selectNode('thought', id, props.user, props.channel.color, position);
            newNode.data.delete = confirmElementsRemove;
            newNode.data.user = props.user;
            setElements((es) => es.concat(newNode));
            setIsWorking(false)
        }
    }

    useEffect(() => {
        //TODO Pull from Nodes collection here. 
       if(props.channel && props.channel.flow!== ''){
           let f = JSON.parse(props.channel.flow);
           let dbElements = f.elements;
            for (let node of dbElements){
                if (node.targetHandle || node.sourceHandle){
                    let index = dbElements.indexOf(node);
                    dbElements.splice(index, 1)
                }
                if (node.data) {
                    node.data.delete = confirmElementsRemove
                    node.data.user = props.user;
                    node.draggable = props.authenticated && props.user.email === 'testy@test.com'
                    node.data.authenticated = props.authenticated && props.user.email === 'testy@test.com'
                }
            }
           setElements(dbElements)
        }
    }, [props.channel]);


    return (
        <ReactFlowProvider >
        
        <Box style = {{zIndex: 0, height: '100vh', overflow:'hidden'}} borderColor = {'#9B9B9B'}>
            <Box style = {{height: '85vh',display:isMobile ? 'none' : 'default', width: props.baseWidth, position:'absolute',}} display = 'flex' flexDirection = 'row' justifyContent = 'flex-end' alignItems='center'>
                <Box display = 'flex' flexDirection ='column' justifyContent = 'flex-start' alignItems = 'center' style = {{zIndex: 10,  marginRight: 20,}}>
                    <FlowController authenticated = {props.authenticated} setIsAdding = {setIsAdding} isAdding = {isAdding} user = {props.user} color = {props.channel.color} buttonStyle = {buttonStyle} addNode = {addNode} />
                </Box>
            </Box>
            <Box
                flexDirection ='row' justifyContent = 'center' alignItems = 'center'
                style={{width: props.baseWidth, height: '100vh', overflow: 'hidden'}}
            >
            {/* { isChatOpen
                                ? <ChatBase chatWidth = {chatWidth} channel={props.channel} messages={props.messages} automations={props.automations} user={props.user} />
                                : null
                            } */}
                <ProjectHeader isMobile = {isMobile} authenticated = {props.authenticated} baseWidth = {props.baseWidth} openChat = {props.openChat} isChatOpen = {props.isChatOpen} handleClickOpenSettings = {props.handleClickOpenSettings} user = {props.user} channel = {props.channel} />
                <div style = {{ width: props.baseWidth, height: '100vh',translate: 'none'}} className="reactflow-wrapper" ref={reactFlowWrapper}>
                <ReactFlow
                    onKeyPress={keyPress}
                        // key={ refreshKey}
                    // nodesDraggable = {false}
                    elementsSelectable ={props.authenticated}
                    nodeTypes={nodeTypes}
                    minZoom={0.05}
                    panOnScroll={true}
                    maxZoom={1.5}
                    style = {{ cursor: isAdding? 'crosshair' : null, overflow: 'hidden', translate: 'none', transform:'none', background: '#242527'}}
                    elements={elements}
                    onLoad={onLoad}
                    selectNodesOnDrag = {true}
                    defaultPosition={props.user.projectIDs[props.channel.channelID].viewPort}
                    defaultZoom={props.user.projectIDs[props.channel.channelID].zoom}
                    onNodeDragStop = {(e,n) => onNodeDragStop(e,n)}
                    // elementsSelectable={props.authenticated}
                        //onNodeDrag = {(e,n)=> {console.log(e, n)}}
                    onElementsRemove={onElementsRemove}
                    onConnect={onConnect}
                    onEdgeUpdate={onEdgeUpdate}
                    connectionMode={'loose'}
                    zoomOnDoubleClick={false}
                    onClick={(e)=>onBaseClick(e)}
                    onDoubleClick={(event)=> onNodeDoubleClick(event)}
                    onlyRenderVisibleElements={false}
                    onElementClick={onElementClick}
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                    >
                          <Controls style = {{ margin: 10,backgroundColor:props.user.theme === 'light' ? 'white' : '#242527'}}/>

                    <MiniMap
                        maskColor ={'#363638'}
                        style = {{
                            backgroundColor:'#363638',
                            boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.95)',
                            margin: 10,}}
                        nodeColor={(node) => {
                            switch (node.type) {
                            case 'input':
                                return '#A7A3E0';
                            case 'default':
                                return '#A7A3E0';
                            case 'output':
                                return '#A7A3E0';
                            default:
                                return '#A7A3E0';
                            }
                        }}
                        nodeStrokeWidth={3}
                    />

                    <Background
                        variant = "dots"
                        color = {props.user.theme  === 'light' ? "#4C4C4C" : 'white'}
                        style = {{backgroundColor:props.user.theme === 'light' ? '#FBFBFB' : '#242527'}}
                        gap={20}
                        // size={1}
                    />
                        <Box display ='flex' flexDirection ='row' container justifyContent = 'flex-end' alignItems = 'space-between'>
                            <Box style = {{marginRight: 150, marginLeft: 20, zIndex: 10, marginTop: 10,}}>
                                {saving
                                    ?
                                    <Box display ='flex' alignItems = 'center'  justifyContent = 'center' flexDirection = {'row'}>
                                        <p style = {{color: props.user.theme === 'dark' ? 'white ' : 'black'}}> Saving </p>
                                        <PuffLoader color={props.user.theme === 'dark' ? 'white ' : 'black'} loading={true} size={25} />
                                    </Box>
                                    :
                                    <Box display ='flex' alignItems = 'center'  justifyContent = 'center' flexDirection = {'row'}>
                                         {/*<p> Saved </p>*/}
                                         {/*<BiCheck size ={15} />*/}
                                     </Box>
                                }
                            </Box>
                        </Box>

            </ReactFlow>
                </div>
                <Dialog
                    open={open}
                    maxWidth={'xs'}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText style = {{fontSize: 14}} id="alert-dialog-description">
                            Are you sure you want to delete?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={confirmElementsRemoveBase} color="primary" autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
        </ReactFlowProvider>
    );
};


const drawerWidth = 72;
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor:'white',
        display: 'flex',
    },
    rootView: {
        height: '100vh',
        flexGrow: 1,
        overflow:'hidden',
    },
    box:{
        flexGrow: 1,
        padding: 0,
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        margin: 0,
    },

    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    privateBoard: {
        height: '90vh',
    },
    container: {
        flexGrow: 1,
    },
    appBarSpacer: theme.mixins.toolbar,
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: '#F8F8F8',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },

    toolbar: {
        paddingRight: 25,
        backgroundColor:'white',
    },

    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),

    },
}));

export default BaseChart;

{/*<Box border = {1} display = 'flex' style = {{boxShadow: "0px 0px 10px #ECECEC",width: '10vw'}}>*/}
{/*</Box>*/}

{/*<Box display = 'flex' style = {{boxShadow: "0px 0px 10px #ECECEC",height: '10vh'}}>*/}
{/*</Box>*/}
{/*<Divider orientation={'vertical'}/>*/}

{/*<Box*/}
{/*    border={1}*/}
{/*    borderColor = {buttonStyle.borderColor}*/}
{/*    borderRadius = {100}*/}
{/*    style = {{ height: 70, zIndex: 10, marginTop: 70, width: 70, margin: 20,  backgroundColor:'white', boxShadow: "0px 0px 20px #EBEFFF", }}*/}
{/*>*/}
{/*    <FeedController buttonStyle = {buttonStyle} openChat = {props.openChat}/>*/}

{/*</Box>*/}


// {isChatOpen
//     ?
//     <Box borderRadius = {10} style = {{marginLeft: 38, marginTop: 100, width: '28vw', minWidth: 300, zIndex: 50, position:'absolute'}}>
//         <Rooms channel={props.channel} messages={props.messages} automations={props.automations} user={props.user}/>
//     </Box>
//     : null
// }



//TODO make this more efficient.
// const onNodeDoubleClick = (event, node) => {
    // if(node.data.type === 'box' && node.data.actives){
    //     for (let i = 0; i < elements.length; i++){
    //         if (elements[i].id === node.id){
    //             let repeat = false;// console.log(node,elements[i].data.actives)
    //             for (let j = 0; j < elements[i].data.actives.length; j++){
    //                 console.log(elements[i].data.actives[j].email, props.user.email)
    //                 if (elements[i].data.actives[j].email === props.user.email){
    //                     repeat = true;
    //                 }
    //
    //             }
    //         if (!repeat) {
    //             elements[i].data.actives.push(props.user)
    //             //remove old active
    //             for (let l = 0; l < elements.length; l++){
    //                 if (elements[l].data && elements[l].data.actives) {
    //                     for (let k = 0; k < elements[l].data.actives.length; k++) {
    //                         if (elements[l].data.id !== elements[i].data.id && elements[l].data.actives[k].email === props.user.email) {
    //                             console.log('removing')
    //                             let index = elements[i].data.actives.indexOf(elements[l].data.actives[k])
    //                             elements[l].data.actives.splice(index, 1)
    //                             elements[l].data.style.borderColor = 'grey';
    //                             console.log(elements[l])
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //         break;
    //         }
    //     }
    //     setElements(elements)
    //     setRefreshKey('_'+ Math.random().toString());

    // }
// };


//
// const onNodeMouseLeave = (event, node) => {
//     let e = elements.slice();
//     for (let i = 0; i < e.length;i++){
//         if (node.id === e[i].id){
//             if (e[i].data.className === 'nodrag') {
//                 e[i].data.className = '';
//                 triggerAutoSave()}
//         }
//     }
//     setElements(e)
//
// };



// const addDocumentToList = (docNode, listNode) => {
//     let e = elements.slice();
//     for (let i = 0; i < e.length;i++){
//         if (listNode.id === e[i].id){
//             console.log('FOUND', e[i]);
//             let list = e[i].data.listData;
//             let newTaskID = `task - `+ Math.random().toString();
//             let newTask = {id: newTaskID, title:docNode.data.title,  content: docNode.data.content};
//             list.columns[list.columnOrder[0]].taskIds.push(newTaskID);
//             list.tasks[newTaskID] = newTask;
//         }
//     }
//     setElements(e);
//     setElementsToRemove(null);
//     setRefreshKey('_'+ Math.random().toString());
//     let rem = [docNode]
//     setElements((els) => removeElements(rem, els));
//     setElementsToRemove(null);
//     triggerAutoSave();
// };



{/*<Controls*/}
{/*    style = {{backgroundColor:props.user.theme === 'light' ? 'white' : '#363638'}}*/}
{/*    />*/}
{/*<MiniMap*/}
{/*    nodeColor={props.channel.color}*/}
{/*    nodeStrokeColor={'#CDCDCD'}*/}
{/*    nodeStrokeWidth={3}*/}
{/*    nodeBorderRadius={5}*/}
{/*    style = {{margin: 10, marginRight: 20, border:2, borderColor: 'black', boxShadow: `0px 3px 10px rgba(0, 0, 0, 0.15)`,*/}
{/*    }}*/}
{/*/>*/}


{/*<AppBar*/}
{/*    style={{ background: 'transparent', zIndex: 100, boxShadow: 'none' }}*/}
{/*    color = '#F7F7F7'*/}
{/*    // position={'static'}*/}
{/*>*/}


//ONNODEDRAGSTOP

// if (node.type === 'documentNodes') {
//     for (let i = 0; i < elements.length; i++) {
//         if (elements[i].type === 'kanbanNodes') {
//             let r1 = {
//                 left: node.position.x,
//                 right: node.position.x + node.data.size[0],
//                 bottom: -node.position.y,
//                 top: -node.position.y + node.data.size[1]
//
//             };
//             let node2 = elements[i];
//             let r2 = {
//                 left: node2.position.x,
//                 right: node2.position.x + node2.data.size[0],
//                 bottom: -node2.position.y,
//                 top: -node2.position.y + node2.data.size[1]
//
//             };
//             if ((r1.left > r2.left &&
//                 r1.right < r2.right &&
//                 r1.top < r2.top &&
//                 r1.bottom < r2.bottom)) {
//                 console.log('intersect!')
//                 addDocumentToList(node, elements[i])
//             }
//         }
//     }
// }