import React, {memo, useEffect} from 'react';
import Box from "@material-ui/core/Box";
import {Handle} from "react-flow-renderer";
import TextField from "@material-ui/core/TextField/TextField";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import {makeStyles} from "@material-ui/core";
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { Rnd } from "react-rnd";
import { BiEdit,BiRctangle, BiMove,BiChevronUp, BiChevronDown, BiText,BiChevronLeft,BiCheckboxChecked,BiListUl,BiUserCircle,BiMessageAltDetail} from "react-icons/bi";
import IconButton from "@material-ui/core/IconButton";
import {db, storage} from "../../../../api/firebase"
import Button from "@material-ui/core/Button";


let tinycolor = require("tinycolor2");



let global_bg_c = 'white';

export default function ImageNode(props) {
    const classes = useStyles();
    const [done, setDone] = React.useState(props.data.done)
    const [text, setText] = React.useState(props.data.text);
    const [img, setImg] = React.useState(props.data.img)
    const [textColor, setTextColor] = React.useState(props.data.textColor);
    const [backgroundColor, setBackGroundColor] = React.useState(props.data.backgroundColor)
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const [size, setSize] = React.useState(props.data.size);
    const [editing, setEditing] = React.useState(false)


    const handleFireBaseUpload = async (e, imageAsFile) => {
        e.preventDefault();
        console.log('start of upload');
        if(imageAsFile === '' ) {
            console.error(`not an image, the image file is a ${typeof(imageAsFile)}`)
            return;
        }
        if (imageAsFile) {
            const uploadTask = storage.ref(`/images/${imageAsFile.name}`).put(imageAsFile)
            //initiates the firebase side uploading
            uploadTask.on('state_changed',
                (snapShot) => {
                    //takes a snap shot of the process as it is happening
                    console.log(snapShot)
                }, (err) => {
                    //catches the errors
                    console.log(err)
                }, async () => {
                    // gets the functions from storage refences the image storage in firebase by the children
                    // gets the download url then sets the image from firebase as the value for the imgUrl key:
                    storage.ref('images').child(imageAsFile.name).getDownloadURL()
                        .then(fireBaseUrl => {
                            setImg(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
                        })
                })
        }
    };



    const handleImageAsFile = async(e) => {
        setEditing(true)
        const image = e.target.files[0];
        // await setImageAsFile(image);
        await handleFireBaseUpload(e, image);
        // setIsLoadingImage(false);
    };
    props.data.img = img



   
    const handleSave = () => {
        console.log(img)
    };

    useEffect(() => {

        if (props.data.textContent) {
            let parsed = EditorState.createWithContent(convertFromRaw(JSON.parse(props.data.textContent)))
            setEditorState(parsed);
        }
        global_bg_c = backgroundColor;
    }, []);


    return (
        <>
            <Box
                style = {{margin: 5, border: 1, borderColor:'white'}}
                // border = {1}
                // borderColor = {data.color}
                display = 'flex'
                flexDirection ='column'
                justifyContent = 'flex-start'
                border={2}
                borderColor = 'white'
                className = 'nodrag'


            >
            {img ? 
            <img style = {{width:props.size[0]-15, border: 1, borderColor:'white', height:props.size[1]-15}} src= {img.imgUrl}></img>

            : 
            <>
            <input className={classes.input} id="contained-button-file" accept="image/*" type ='file' onChange={handleImageAsFile} />
            <label htmlFor="contained-button-file">
            {/* <Button variant="contained"> Upload </Button> */}
            </label>

            </>

            }
             
             {/* <Handle
                    type="source"
                    id = 'k'
                    position="bottom"
                    style={{ zIndex: 12, backgroundColor: props.data.color,boxShadow: `0px 3px 10px rgba(0, 0, 0, 0.15)` }}
                    // onConnect={(params) => console.log('handle onConnect', params)}
            />
            <Handle
                    type="source"
                    id = 'j'
                    position="top"
                    style={{ zIndex: 12, margin:- 5, backgroundColor: props.data.color,boxShadow: `0px 3px 10px rgba(0, 0, 0, 0.15)` }}
                    // onConnect={(params) => console.log('handle onConnect', params)}
            /> */}


            </Box>




        </>



    );
};


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },

    cont1 : {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    },

    cont2 : {
        height: '100%',
        width: '100%',
        overflow: 'auto',
        paddingRight: 20,
    },
    box:{
        padding: 0,
        display: 'start',
        overflow: 'auto',
        flexDirection: 'column',
    },

    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },

    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },

    fixedHeight: {
        height: 350,
    },
    popover: {
    },

    toolbar: {
        fontSize: 12,
        backgroundColor: global_bg_c,


    }
}));
